import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, forwardRef, Fragment, useId } from 'react';
import { sentenceCase } from 'sentence-case';
import styled, { css, CSSProperties } from 'styled-components';
import Colors from '../../../Colors';
import { FadeInBottom, Shake } from '../Animations';
import { ActivityIndicator } from '../Spinner';
import { Checkbox, CheckboxFormWrapper, CheckboxFrom, CheckboxFromContainer, CheckboxItemContainer, CheckboxItemDescription, CheckboxItemName, StyledRadioButton } from './styled';
import { Divider } from '../SideTabs/styles';

export const ConfirmationContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const ConfirmationTitle = styled.h1`
  text-align: center;
  font-size: 32px;
  font-weight: 800;
  color: #212121;
  margin-bottom: 30px;
  font-family: Poppins;
`;
export const ConfirmationLogo = styled(GatsbyImage)`
  height: 200px;
  width: 200px;
  margin-bottom: 20px;
`;
export const ConfirmationMessage = styled.h3`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #404040;
  width: 545px;
  line-height: 26px;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;
export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 0 0;
  width: 100%;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
export const PageLogo = styled(GatsbyImage)``;

export const PageTitle = styled.h3`
  font-size: 25px;
  font-weight: bold;
  font-family: Poppins;
  border-width: 0px 0px 0px 1px;
  padding-left: 16px;
  margin-left: 16px;
  border-color: #b4b4b4;
  border-style: solid;
  @media (max-width: 700px) {
    border-width: 0px 0px 0px 0px;
    padding-left: 0px;
    margin-left: 0px;
  }
`;
export const PageFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 33px 0 0 0;
`;
export const PageCR = styled.p`
  font-size: 10px;
  font-weight: bold;
  color: #878787;
  margin-bottom: 11px;
`;

export const FormWrapper = styled.div<{ maxWidth?: number; height?: string; width?: string; display?: string; flexDirection?: string }>`
  display: ${props => props.display || 'block'};
  flex-direction: ${props => props.flexDirection || 'column'};
  height: ${props => props.height || 'auto'};
  width: ${props => props.width || 'auto'};
  justify-content: center;
  align-items: center;
  max-width: ${props => props.maxWidth || 300}px;
  border-radius: 15px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  background-color: ${Colors.white};
  border: none;
  padding: 0;
  animation: ${FadeInBottom} 0.3s ease-in-out forwards;
`;
export const FormSection = styled.div<{ bgColor?: string; paddingX?: number; paddingY?: number; flexDirection?: string; alignItems?: string; width?: string; gap?: string }>`
  display: flex;
  align-items: ${props => props.alignItems || 'stretch'};
  justify-content: space-between;
  flex-direction: ${props => props.flexDirection || 'column'};
  width: ${props => props.width || 'auto'};
  /* gap: ${props => props.gap || '8px'}; */
  ${props =>
    props.paddingX &&
    css`
      padding-left: ${props.paddingX}px;
      padding-right: ${props.paddingX}px;
    `}
  ${props =>
    props.paddingY &&
    css`
      padding-top: ${props.paddingY}px;
      padding-bottom: ${props.paddingY}px;
    `}
  ${props =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `};
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
export const InputsWrapper = styled.div<{ noWrap?: boolean; gap?: number; noFlex?: boolean; padding?: string; noGap?: boolean; isCompact?: boolean }>`
  display: flex;
  padding: ${props => (props.isCompact ? '20px 40px 20px 20px' : '20px 40px')};
  overflow-y: scroll;
  width: 100%;
  gap: ${props => props.gap || 10}px;
  gap: ${props => props.noGap && '0'};
  ${props =>
    props.noWrap
      ? css`
          flex-wrap: nowrap;
          flex-direction: column;
        `
      : css`
          flex-wrap: wrap;
        `}

  ${props =>
    props.gap &&
    css`
      justify-content: flex-start;
      gap: ${props.gap}px;
    `}

  ${props =>
    props.noFlex &&
    css`
      display: block;
    `}
  @media (max-width: 700px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export const FormFooterMessage = styled.div`
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 26px;
  padding: 0 32px 0 32px;
`;
export const FormHeaderWrapper = styled.div<{ fullWidth?: boolean; noMargin?: boolean }>`
  margin-bottom: ${props => (props.noMargin ? '0' : '21px')};
  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;
export const FormHeaderLabel = styled.legend<{ margin?: string; fontSize?: number }>`
  font-size: 25px;
  font-weight: 800;
  color: #212121;
  margin: ${props => props.margin || '0'};
  padding: 0;
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `}
`;
export const FormParagraph = styled.p`
  padding: 32px 32px 12px 32px;
  font-size: 16px;
  font-weight: 700;
`;
export const LoginFormButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 16px 0 32px 0;
  align-items: center;
`;
export const FormButtonsContainer = styled.div<{ children: any; justifyContent?: string; flexDirection?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'center'};
  padding: 20px;
  padding-left: 0;
  flex-direction: ${props => props.flexDirection || 'row'};
  width: 100%;
  gap: 16px;
  border-top: 1px solid #e8e8e8;
`;
export const FormSubmitButtonStyled = styled.button<{ error?: boolean; secondary?: boolean; danger?: boolean; black?: boolean; disabled?: boolean; backgroundColor?: string }>`
  padding: 8px 13px;
  border: none;
  border-radius: 18px;
  font-size: 12px;
  color: ${props => (props.black ? Colors.white : props.secondary ? (props.danger ? Colors.orange : Colors.black) : Colors.white)};
  background-color: ${props => props.backgroundColor ?? (props.black ? Colors.black : props.secondary ? 'none' : props.danger ? Colors.orange : Colors.toolsBackgroundColorHover)};
  font-weight: 700;
  min-width: 100px;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    filter: brightness(95%);
  }
  ${props =>
    props.error &&
    css`
      animation: ${Shake} 0.4s ease;
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      background-color: ${Colors.placeholder};
      color: #f1f1f1;
      &:hover {
        cursor: not-allowed;
        filter: brightness(100%);
      }
    `}
`;
export const InputGroup = styled.div<{ width?: string; flex1?: boolean; direction?: 'row' | 'column'; maxWidth?: string }>`
  display: flex;
  max-width: ${props => props.maxWidth || '100%'};
  flex-direction: ${props => props.direction || 'column'};
  width: ${props => props.width || '100%'};
  ${props =>
    props.flex1 &&
    css`
      flex: 1;
    `}
`;

export const AlternateBackground = styled.div<{ firstColor: string; secondColor: string; padding?: string; borderRadius?: string }>`
  &:nth-child(odd) {
    background-color: ${props => props.firstColor};
  }
  &:nth-child(even) {
    background-color: ${props => props.secondColor};
  }
  border-radius: ${props => props.borderRadius || '3px'};
  padding: ${props => props.padding || '0'};
`;

export const WideInputGroup = styled.div<{
  marginBottom?: number;
  flexDirection?: 'row' | 'column';
  marginTop?: number;
  marginLeft?: number;
  maxWidth?: number;
  gap?: number;
  noMarginBottom?: boolean;
}>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  flex-wrap: ${props => (props.flexDirection === 'row' ? 'wrap' : 'nowrap')};
  margin-bottom: ${props => props.marginBottom ?? 20}px;
  margin-bottom: ${props => props.noMarginBottom && '0'};
  margin-top: ${props => props.marginTop ?? 0}px;
  margin-left: ${props => props.marginLeft ?? 0}px;
  width: 100%;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '600px')};
  gap: ${props => props.gap ?? 0}px;
`;
export const InputContainer = styled.div<{ width?: number; isRow?: boolean; flex?: number; marginBottom?: number; wrap?: string; gap?: number; alignCenter?: boolean; autoHeight?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isRow ? 'row' : 'column')};
  justify-content: center;
  gap: ${props => props.gap}px;
  margin-bottom: ${props => props.marginBottom ?? 8}px;
  height: 100%;
  ${props =>
    props.width &&
    css`
      width: ${props.width}%;
    `}
  ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
      justify-content: space-between;
    `}
  ${props =>
    props.wrap &&
    css`
      flex-wrap: ${props.wrap};
    `}
  ${props =>
    props.alignCenter &&
    css`
      align-items: center;
    `}
    ${props =>
    props.autoHeight &&
    css`
      height: auto;
    `}
`;
export const RemoveRowContainer = styled.div<{ height?: string; noMargin?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: auto;
  margin-right: 6px;
  ${props =>
    props.noMargin &&
    css`
      margin: 0;
    `}
  height: 30px;
  width: 5%;
  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;

export const RemoveImage = styled.img<{ showMargin?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 15px;
  object-fit: cover;
  ${props =>
    props.showMargin &&
    css`
      margin-bottom: 60px;
    `}
  &:hover {
    cursor: pointer;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  margin-bottom: 8px;
  width: 100%;
`;
export const InputsRow = styled.div<{ marginBottom?: number }>`
  display: flex;
  margin-bottom: ${props => props.marginBottom ?? 8}px;
  width: 100%;
  padding: 4px;
`;
export const HeaderLabel = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: #7c7c7c;
  ${props =>
    props.width &&
    css`
      width: ${props.width}%;
    `}
`;

export const SecondCta = styled.div`
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 28px;
  &:hover {
    cursor: pointer;
  }
`;

export const InputWrapper = styled.div<{ isCompact?: boolean; padding?: string; gap?: string; isMini?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${props => (props.isMini ? 1 : 'unset')};
  width: 100%;
  overflow: scroll;
  padding: ${props => (props.isCompact ? '20px 60px' : '20px 60px 20px 20px')};
  padding: ${props => (props.padding || props.isCompact ? '20px 60px' : '20px 60px 20px 20px')};
  gap: ${props => props.gap || '20px'};
  @media (max-width: 768px) {
    padding: 20px;
  }
`;
export const FormInput = styled.input<{
  error?: string | boolean;
  padding?: string;
  height?: number;
  fontSize?: number;
  flexBasis?: number;
  fullWidth?: boolean;
  flexOne?: boolean;
  borderBox?: boolean;
  paddingTopBottom?: number;
  borderRadius?: number;
  backgroundColor?: string;
  noBorder?: boolean;
  defaultBorder?: boolean;
  width?: number;
}>`
  height: ${props => props.height || 37}px;
  padding: ${props => (props.padding ? props.padding : '8px 10px')};
  border-radius: ${props => (props.borderRadius ? `${props.borderRadius}px` : '3px')};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '14px')};
  border: ${props => (props.noBorder ? 'none' : `solid 1px ${props.error ? Colors.error : Colors.inputBorder}`)};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'white')};
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  outline: none;
  &:focus {
    border: ${props => (props.noBorder ? 'none' : `solid 1px ${props.error ? Colors.error : Colors.black}`)};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
`;
export const FormTextArea = styled.textarea<{
  error?: string | boolean;
  padding?: string;
  height?: number;
  fontSize?: number;
  fullWidth?: boolean;
  borderRadius?: number;
  backgroundColor?: string;
  noBorder?: boolean;
  defaultBorder?: boolean;
  width?: number;
  isShort?: boolean;
}>`
  min-height: ${props => (props.isShort ? '60px' : '200px')};
  max-width: 600px;
  width: 100%;
  padding: 8px;
  font-size: 12px;
  border-radius: 4px;
  resize: vertical;
  border-radius: ${props => (props.borderRadius ? `${props.borderRadius}px` : '3px')};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '14px')};
  border: ${props => (props.noBorder ? 'none' : `solid 1px ${props.error ? Colors.error : Colors.inputBorder}`)};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'white')};
  outline: none;
  &:focus {
    border: ${props => (props.noBorder ? 'none' : `solid 1px ${props.error ? Colors.error : Colors.black}`)};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
`;
export const ColorPicker = styled((props: React.InputHTMLAttributes<HTMLInputElement>) => <input {...props} type="color" />)<{
  error?: string;
  height?: number;
  padding?: number;
  width?: number;
  autoHeight?: boolean;
}>`
  ${props =>
    props.padding &&
    css`
      padding: calc(8px - ${props.padding}%);
    `}
  border-radius: 4px;
  font-size: 16px;
  border: solid 1px ${props => (props.error ? Colors.error : '#929292')};
  width: ${props => (props?.width ? `${props.width}px` : '100%')};
  height: ${props => props.height || 28}px;
  ${props =>
    props.autoHeight &&
    css`
      height: auto;
    `}
  &:focus {
    outline-color: ${props => (props.error ? Colors.error : Colors.primary)};
  }

  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  height: 38px;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`;

export const FormSelect = styled.select<{
  error?: string | boolean;
  height?: number;
  fontSize?: number;
  padding?: string;
  fullWidth?: boolean;
  width?: number;
  backgroundColor?: string;
  borderRadius?: string;
  noBorder?: boolean;
  fontWeight?: number;
}>`
  font-size: 16px;
  border-radius: 4px;
  border: ${props => (props.noBorder ? 'none' : `solid 1px ${props.error ? Colors.error : '#929292'}`)};

  height: ${props => props.height || 28}px;
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `};
  ${props =>
    props.error &&
    css`
      color: ${Colors.error};
    `};

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
      min-width: ${props.width}px;
    `}

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  &:focus {
    outline-color: ${props => (props.error ? Colors.error : Colors.primary)};
  }
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'white')};

  border-radius: ${props => (props.borderRadius ? props.borderRadius : 'none')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  cursor: pointer;
  padding: ${props => (props.padding ? props.padding : '0')};
`;
export const FormLabel = styled.label<{
  error?: string | boolean;
  marginBottom?: number;
  bold?: boolean;
  color?: string;
  clickable?: boolean;
  wrap?: boolean;
  marginLeft?: string;
  width?: string;
  normalWhiteSpace?: boolean;
  fontWeight?: number;
  marginTop?: string;
  mobileOverflow?: boolean;
}>`
  white-space: ${props => (props.wrap ? 'wrap' : 'nowrap')};
  white-space: ${props => (props.normalWhiteSpace ? 'normal' : 'nowrap')};
  margin-bottom: 8px;
  font-size: 12px;
  color: ${props => (props.error ? Colors.error : props.color || Colors.black)};
  width: ${props => (props.width ? props.width : 'auto')};
  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
  ${props =>
    props.bold &&
    css`
      font-weight: 800;
      font-size: 13px;
      color: black;
      margin-bottom: 12px;
    `}

  ${props =>
    props.marginBottom !== undefined &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
  ${props =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `};
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 768px) {
    ${props =>
      props.mobileOverflow &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}
  }
`;

export const FormSecondaryText = styled.span<{ marginBottom?: number; fontSize?: number; color?: string }>`
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '10px')};
  color: ${props => props.color || Colors.black};
  margin-bottom: ${props => (props.marginBottom ? `${props.marginBottom}px` : '8px')};
`;

export const FormInputsContainer = styled.div<{ flexDirection?: 'row' | 'column'; gap?: number; noFlex?: boolean; nowrap?: boolean; padding?: string }>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  flex-wrap: ${props => (props.flexDirection === 'row' ? 'wrap' : 'nowrap')};
  ${props =>
    props.nowrap &&
    css`
      flex-wrap: nowrap;
    `}
  width: 100%;
  gap: ${props => props.gap || 0}px;
  padding: ${props => props.padding || '0'};
`;

export const SectionLabel = styled.label<{ marginBottom?: number; fontSize?: number; padding?: number }>`
  display: inline-block;
  margin-bottom: 20px;
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
  font-size: 16px;
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `};
  font-weight: 800;
  color: black;
  white-space: nowrap;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}px;
    `}
`;

export const SectionSpan = styled.span`
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 800;
  color: black;
`;

export const RadioLabel = styled.label<{ alignTriggerEnd?: boolean; margin?: string; fontSize?: number; fontWeight?: number }>`
  display: flex;
  align-items: center;
  margin-right: 17px;
  font-size: 13px;
  font-weight: 700;
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `}
  ${props =>
    props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}
  ${props =>
    props.alignTriggerEnd &&
    css`
      width: 100%;
    `}
`;
export const FormError = styled.span<{ error?: string }>`
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  color: ${Colors.error};
`;
export const FormLinkButtonStyled = styled.button<{ textAlign?: 'left' | 'right' }>`
  border: none;
  margin-top: -12px;
  padding: 0;
  font-size: 12px;
  font-weight: 800;
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  color: ${Colors.primary};
  background-color: transparent;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const FormSubmitButton = (props: {
  error?: boolean;
  loading?: boolean;
  children: any;
  onClick?: any;
  secondary?: boolean;
  backgroundColor?: string;
  danger?: boolean;
  type?: 'button' | 'submit' | 'reset';
  black?: boolean;
  disabled?: boolean;
}) => (
  <FormSubmitButtonStyled
    type={props?.type}
    onClick={props.onClick}
    error={props.error}
    secondary={props?.secondary}
    danger={props?.danger}
    backgroundColor={props?.backgroundColor}
    black={props?.black}
    disabled={props?.disabled || props?.loading}
  >
    {props.loading ? <ActivityIndicator size={19} color={props.secondary ? Colors.primary : Colors.white} /> : props.children}
  </FormSubmitButtonStyled>
);

export const selectTheme = (theme: any) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: Colors.inputBorder,
    primary: Colors.inputBorder,
    danger: Colors.error
  }
});

const Item = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const RadioCheckCircle = styled.label`
  position: absolute;
  top: 10%;
  left: 4px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;
const CheckBoxTick = styled.label<{ alignTriggerEnd?: boolean; top?: string }>`
  position: absolute;
  top: ${props => props.top || '10%'};
  left: 4px;
  ${props =>
    props.alignTriggerEnd &&
    css`
      right: 8%;
      top: 27%;
      left: unset;
    `}
  width: 16px;
  height: 16px;
  border-radius: 5px;
  background: white;
  border: 1px solid ${Colors.black};
`;
export const Icon = styled.svg`
  fill: none;
  stroke: transparent;
  stroke-width: 2px;
`;

const CustomLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioButton = styled.input<{ hoverBg?: string; hoverCircle?: string; checkedBg?: string; alignTriggerEnd?: boolean; position?: string; fontSize?: number; fontWeight?: number }>`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 10px;

  &:hover ~ ${RadioCheckCircle} {
    background: ${props => props.hoverBg || '#bebebe'};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 4px;
      background: ${props => props.hoverCircle || '#eeeeee'};
    }
  }
  &:checked + ${RadioCheckCircle} {
    background: ${props => props.checkedBg || '#3F3F3F'};
    border: 1px solid ${props => props.checkedBg || '#3F3F3F'};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 4px;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
  &:focus-visible + ${RadioCheckCircle} {
    background: ${Colors.placeholder};
  }

  &:hover ~ ${CheckBoxTick} {
    background: #bebebe;
    ${Icon} {
      visibility: 'visible';
      stroke: ${Colors.grey};
    }
  }
  &:checked + ${CheckBoxTick} {
    ${Icon} {
      visibility: 'visible';
      stroke: ${Colors.black};
    }
  }
  &:focus-visible + ${CheckBoxTick} {
    background: ${Colors.placeholder};
  }
  ${props =>
    props.alignTriggerEnd &&
    css`
      right: 8%;
      top: 27%;
      position: absolute;
      margin-right: 0;
    `}

  ${props =>
    props.fontSize &&
    css`
      & + ${RadioLabel} {
        font-size: ${props.fontSize}px;
      }
    `}
  ${props =>
    props.fontWeight &&
    css`
      & + ${RadioLabel} {
        font-weight: ${props.fontWeight};
      }
    `}

    cursor: pointer;
`;

type RadioBtnsGroupProps = {
  options: string[];
  as?: string[];
  name: string;
  defaultValue?: string | boolean | string[];
  inputType?: 'radio' | 'checkbox';
  asType?: 'text' | 'color';
  onChange: (e: any) => void;
  noLabel?: boolean;
  itemStyle?: CSSProperties;
  alignTriggerEnd?: boolean;
  customLabel?: string[];
  fontSize?: number;
  fontWeight?: number;
  disabled?: boolean;
  margin?: string;
  top?: string;
  useSentenceCase?: boolean;
};
export const RadioBtnsGroup = forwardRef(
  (
    {
      options,
      as,
      name,
      defaultValue,
      inputType = 'radio',
      onChange,
      noLabel,
      itemStyle,
      asType = 'text',
      alignTriggerEnd,
      customLabel,
      disabled = false,
      fontSize,
      fontWeight,
      margin,
      top,
      useSentenceCase = true
    }: RadioBtnsGroupProps,
    ref
  ) => {
    const random = useId();
    return (
      <>
        {options.map((option, idx) => {
          const item = (
            <>
              <Item key={idx} style={itemStyle || {}}>
                <RadioButton
                  id={idx + '_' + option + '_' + random}
                  type={inputType}
                  name={name}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                  value={option}
                  defaultChecked={inputType === 'radio' ? defaultValue === option : typeof defaultValue === 'boolean' ? defaultValue : defaultValue?.includes?.(option)}
                  onChange={onChange}
                  alignTriggerEnd={alignTriggerEnd}
                  disabled={disabled}
                />
                {inputType === 'radio' ? (
                  <RadioCheckCircle style={asType === 'color' ? { background: option } : {}} />
                ) : (
                  <CheckBoxTick alignTriggerEnd={alignTriggerEnd} top={top}>
                    <Icon viewBox="0 0 24 24">
                      <polyline points="20 6 9 17 4 12" />
                    </Icon>
                  </CheckBoxTick>
                )}

                {!noLabel && (
                  <RadioLabel alignTriggerEnd={alignTriggerEnd} htmlFor={idx + '_' + option + '_' + random} margin={margin} fontSize={fontSize} fontWeight={fontWeight}>
                    {as?.[idx] || (useSentenceCase ? sentenceCase(option) : option)}
                  </RadioLabel>
                )}
              </Item>
            </>
          );
          return (
            <Fragment key={idx}>
              {customLabel ? (
                <CustomLabelContainer>
                  <FormLabel>{customLabel[idx]}</FormLabel>
                  {item}
                </CustomLabelContainer>
              ) : (
                item
              )}
            </Fragment>
          );
        })}
      </>
    );
  }
);

export const FormCheckbox: FC<{
  itemsArray: { id: string; name: string; disabled?: boolean; description?: string }[];
  onChange: (...event: any[]) => void;
  value: string[];
  error?: string | boolean;
  fontSize?: number;
  column?: boolean;
  disabled?: boolean;
  children?: JSX.Element;
  reversed?: boolean;
  renderItem?: (item: { id: string; error?: string | boolean; fontSize?: number; disabled?: boolean }) => JSX.Element;
  itemWidth?: number;
  alternate?: boolean;
  noFlexWrap?: boolean;
  noSpaceArround?: boolean;
  itemContainerStyle?: CSSProperties;
  divider?: boolean;
  flexAlign?: string;
  buttonOptionTheme?: boolean;
}> = ({
  itemsArray,
  onChange,
  value,
  error,
  fontSize,
  column,
  disabled,
  children,
  renderItem,
  reversed,
  itemWidth,
  itemContainerStyle,
  alternate,
  noFlexWrap,
  noSpaceArround,
  divider,
  flexAlign,
  buttonOptionTheme
}) => {
  return (
    <CheckboxFromContainer column={column} noFlexWrap={noFlexWrap} buttonOptionTheme={buttonOptionTheme}>
      {itemsArray?.map(({ id, name, description }, index) => {
        const Item = renderItem?.({ id, disabled, error, fontSize });
        const isOdd = index % 2 !== 0;
        return (
          <Fragment key={index}>
            <CheckboxFormWrapper key={id} isOdd={isOdd} alternate={alternate} buttonOptionTheme={buttonOptionTheme}>
              <CheckboxFrom style={itemContainerStyle} buttonOptionTheme={buttonOptionTheme}>
                <input
                  type="checkbox"
                  value={id}
                  onChange={e => {
                    if (value?.includes(e.target.value)) {
                      const newValue = [...value].filter(v => v !== e.target.value);
                      onChange(newValue);
                    } else {
                      const newValue = [...value, e.target.value];
                      onChange(newValue);
                    }
                  }}
                />
                <CheckboxItemContainer
                  checked={value?.includes(id)}
                  fullWidth={column}
                  disabled={disabled}
                  reversed={reversed}
                  itemWidth={itemWidth}
                  noSpaceArround={noSpaceArround}
                  flexAlign={flexAlign}
                  buttonOptionTheme={buttonOptionTheme}
                >
                  <Checkbox error={error} buttonOptionTheme={buttonOptionTheme}>
                    <Icon viewBox="0 0 24 24">
                      <polyline points="20 6 9 17 4 12" />
                    </Icon>
                  </Checkbox>
                  {Item && Item}
                  {!Item && (
                    <div className="flex flex-col">
                      <CheckboxItemName fontSize={fontSize} error={error} disabled={disabled}>
                        {name}
                      </CheckboxItemName>
                      {description && (
                        <CheckboxItemDescription error={error} disabled={disabled}>
                          {description}
                        </CheckboxItemDescription>
                      )}
                    </div>
                  )}
                </CheckboxItemContainer>
              </CheckboxFrom>
              {children}
            </CheckboxFormWrapper>
            {divider && <Divider />}
          </Fragment>
        );
      })}
    </CheckboxFromContainer>
  );
};

export const FormRadio: FC<{
  itemsArray: { id: string; name: string }[];
  onChange?: (...event: any[]) => void;
  value?: string;
  error?: string | boolean;
  fontSize?: number;
  column?: boolean;
  buttonOptionTheme?: boolean;
  buttonsGap?: string;
}> = ({ itemsArray, onChange, value, error, fontSize, column, buttonOptionTheme, buttonsGap }) => {
  return (
    <CheckboxFromContainer column={column} buttonOptionTheme={buttonOptionTheme} buttonsGap={buttonsGap}>
      {itemsArray?.map(({ id, name }) => (
        <CheckboxFrom key={id}>
          <input
            type={'radio'}
            value={id}
            onChange={e => {
              onChange?.(e.target.value);
            }}
            checked={value === id}
          />
          <CheckboxItemContainer checked={value === id} fullWidth={column} noWidth buttonOptionTheme={buttonOptionTheme}>
            <StyledRadioButton error={error} buttonOptionTheme={buttonOptionTheme} />
            <CheckboxItemName fontSize={fontSize} error={error}>
              {name}
            </CheckboxItemName>
          </CheckboxItemContainer>
        </CheckboxFrom>
      ))}
    </CheckboxFromContainer>
  );
};

export const FormFieldSet = styled.fieldset<{ showBorder?: boolean }>`
  border: ${props => (props.showBorder ? `1px solid ${Colors.divider}` : 'none')};
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
`;
export const FormFieldSetLegend = styled.legend`
  font-size: 14px;
  font-weight: 800;
  color: #212121;
`;

export const ScrollableFormFieldSet = styled(FormFieldSet)`
  overflow-y: scroll;
  max-height: 200px;
  box-sizing: border-box;
  padding-bottom: 10px;
  > div {
    max-height: 165px;
  }
`;
export const FromButton = styled.button<{ selected?: boolean }>`
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  border-radius: 8px;
  background: #dadada;
  color: #000;
  font-size: 12px;
  font-weight: 700;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const ListCount = styled.div`
  font-size: 14px;
  color: ${Colors.black};
  // margin-left: 20px;
  // margin-bottom: 10px;
  padding: 8px 20px;
`;

export const InputActionContainer = styled.div`
  display: flex;
  gap: 12px;
`;
