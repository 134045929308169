import React from 'react';
import styled, { css } from 'styled-components';
import Colors from '../../Colors';
import { CenteredLoadingContainer, LoadingOverlayContainer, SpinnerContainer, StyledSpinner, VerticallyCenteredLoaderContainer } from './Shared';

const LineLoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 2px;
`;

const LineLoaderContent = styled.div<{ active: boolean; loadingContent: boolean; size?: number; startingLoaderWidth?: number }>`
  ${({ loadingContent, active, startingLoaderWidth }) => {
    if (active && loadingContent) {
      return css`
        width: ${startingLoaderWidth || 30}%;
        transition: width 1s ease;
      `;
    }
    if (active && !loadingContent) {
      return css`
        width: 100%;
        transition: width 1s ease;
      `;
    }
    return css`
      width: 0%;
    `;
  }};
  height: ${({ size }) => (size ? size : 2)}px;
  border-radius: 2px;
  background-color: ${Colors.black};
`;

export const ActivityIndicator = (props: { size?: number; color?: string }) => {
  return (
    <StyledSpinner {...props}>
      <circle className="path" cx={`${props.size ? props.size / 2 : 50}`} cy={`${props.size ? props.size / 2 : 50}`} r={`${props.size ? (props.size * 2) / 5 : 33}`} fill="none" strokeWidth="4" />
    </StyledSpinner>
  );
};

const PageLoader = (props: { size?: number; color?: string }) => {
  return (
    <SpinnerContainer>
      <ActivityIndicator {...props} />
    </SpinnerContainer>
  );
};

export const LoadingOverlay = (props: { size?: number; color?: string }) => {
  return (
    <LoadingOverlayContainer>
      <ActivityIndicator {...props} />
    </LoadingOverlayContainer>
  );
};

export const CenteredLoader = (props: { size?: number; color?: string }) => {
  return (
    <CenteredLoadingContainer>
      <ActivityIndicator {...props} />
    </CenteredLoadingContainer>
  );
};

export const VerticallyCenteredLoader = (props: { size?: number; color?: string }) => {
  return (
    <VerticallyCenteredLoaderContainer>
      <ActivityIndicator {...props} />
    </VerticallyCenteredLoaderContainer>
  );
};

export const LineLoader = (props: { active: boolean; loadingContent: boolean; size?: number; color?: string; startingLoaderWidth?: number }) => {
  return (
    <LineLoaderContainer>
      <LineLoaderContent {...props} />
    </LineLoaderContainer>
  );
};

export default PageLoader;
