const { version } = require('../../package.json');
const FIREBASE = require('./config-fb-dev');

module.exports = {
  ENV: 'development',
  FIREBASE,
  BASE_API_URL: 'https://collar-api.dev.collar.pet',
  API_URL: 'https://collar-api.dev.collar.pet/graphql',
  SUB_API_URL: 'wss://collar-api.dev.collar.pet/graphql',
  MEET_URL: 'https://meet.collar.pet',
  CLIENT_ID: `WEB-${version}`,
  DYNAMIC_LINK_DOMAIN_PREFIX: 'https://collardev.page.link',
  WEBSITE_PREFIX: 'https://collar.pet',
  BUGSNAG_KEY: 'bb7617aa0f3e7ecc53719ee7caf07d9d',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCZC4rfyCcwVu0Wjp5Hx49_HhAmegEut90'
};
